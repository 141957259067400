import dynamic from "next/dynamic";
import React, { ReactElement } from "react";
import { CloseIcon } from "components/Icons/CloseIcon";
import { SearchIcon } from "components/Icons/SearchIcon";

interface IProps {
  label: string;
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
}

const renderSearchModal = (isOpen: boolean): ReactElement | null => {
  if (!isOpen) {
    return null;
  }

  const SearchModal = dynamic(
    () =>
      import("./components/SearchModal/SearchModal").then(
        (mod) => mod.SearchModal
      ) as never,
    { ssr: false }
  );

  return <SearchModal />;
};

export const Search = ({ label, isOpen, setIsOpen }: IProps): ReactElement => {
  return (
    <li data-testid={isOpen ? "siteHeader-search-active" : "siteHeader-search"}>
      <button
        type={"button"}
        aria-label={label}
        onClick={() => setIsOpen(!isOpen)}
        data-testid="search-toggle"
      >
        <span>
          {isOpen ? (
            <CloseIcon size={16} fill="white" />
          ) : (
            <SearchIcon size={16} fill="white" />
          )}
        </span>
      </button>
      {renderSearchModal(isOpen)}
    </li>
  );
};
