import * as Sentry from "@sentry/nextjs";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Locale } from "interfaces/interfaces";

export interface ILocation {
  countryCode: string | undefined;
}
export const getLocation = async (): Promise<ILocation> => {
  try {
    const res = await fetch(`/api/location`);
    return await res.json();
  } catch (err) {
    Sentry.captureException(err);
    return { countryCode: undefined };
  }
};

export const convertCountryCode = (countryCode: string): Locale | undefined => {
  const countryCodeToLocale = (Object.keys(Locale) as Locale[]).reduce(
    (acc, locale) => {
      // Get country code from last part after "-" in locale.
      const countryCode = (locale.match(/^.*-(.*)$/) || [])[1];
      if (countryCode) {
        acc[countryCode.toLowerCase()] = locale;
      }
      return acc;
    },
    {} as Record<string, Locale | undefined>
  );
  return countryCodeToLocale[countryCode.toLowerCase()];
};
interface IUseCountryCode {
  locale: Locale | null;
  setLocale: Dispatch<SetStateAction<Locale | null>>;
}

export const useGetLocaleFromCountryCode = (): IUseCountryCode => {
  const [locale, setLocale] = useState<Locale | null>(null);

  useEffect(() => {
    (async () => {
      const { countryCode } = await getLocation();
      if (!countryCode) {
        return;
      }
      const locale = convertCountryCode(countryCode);
      if (locale) {
        setLocale(locale);
      }
    })();
  }, []);

  return { locale, setLocale };
};
