import classNames from "classnames";
import { useGlobal } from "contexts/globalContext";
import React, { useEffect } from "react";
import { CloseIcon } from "components/Icons/CloseIcon";
import styles from "./closeButton.module.scss";

export interface IProps {
  className?: string;
  onClick: () => void;
  ["data-testid"]?: string;
}

export const CloseButton = (props: IProps) => {
  const { i18n } = useGlobal();
  const { onClick, className } = props;

  useEffect(() => {
    const keyUpHandler = (event) => {
      if (event.key === "Escape") {
        onClick();
      }
    };
    window.addEventListener("keyup", keyUpHandler);
    return () => {
      window.removeEventListener("keyup", keyUpHandler);
    };
  }, [onClick]);

  return (
    <button
      type="button"
      onClick={onClick}
      className={classNames(styles.closeButton, className)}
      aria-label={i18n.generic_close.text}
      title={i18n.generic_close.text}
      data-testid={props["data-testid"]}
    >
      <CloseIcon size={24} fill="black" />
    </button>
  );
};
