export interface IProps {
  width?: number;
  height?: number;
  fill?: string;
}

export const PodplayLogo = ({ width, height, fill }: IProps) => {
  return (
    <svg
      viewBox="0 0 685 99"
      width={width}
      height={height}
      aria-hidden={true}
      focusable={false}
      role="img"
    >
      <g fill={fill} fillRule="nonzero">
        <path d="M37.08 2.09H.67V97.2h15.9V60.52h20.52c17.53 0 30.43-12.36 30.43-29.35 0-16.72-12.91-29.08-30.44-29.08zm0 43.2H16.56V17.3h20.52c8.29 0 14.4 5.98 14.4 13.86 0 8.02-6.11 14.13-14.4 14.13zM322.38 2.09h-31.25V97.2h31.25c27.04 0 47.55-20.52 47.55-47.96 0-27.18-20.51-47.15-47.55-47.15zm1.09 79.88h-16.44V17.3h16.44c17.39 0 30.16 13.32 30.16 31.93 0 18.75-12.91 32.74-30.16 32.74zM417.75 2.09h-36.41V97.2h15.9V60.52h20.51c17.53 0 30.43-12.36 30.43-29.35.01-16.72-12.9-29.08-30.43-29.08zm0 43.2h-20.51V17.3h20.51c8.29 0 14.4 5.98 14.4 13.86 0 8.02-6.11 14.13-14.4 14.13zM474.13 2.09h-15.89v95.1h61.41V81.97h-45.52zM565.56 2.09L527.65 97.2h16.85l7.47-19.02h40.22l7.34 19.02h17.25L578.87 2.09h-13.31zm-7.74 60.86l14.27-36.41 14.13 36.41h-28.4zM666.1 2.09l-23.64 37.77-24.05-37.77h-19.29l35.19 53.8v41.3h15.89v-41.3l34.51-53.8zM231.49.73H124.03c-27.44 0-48.37 21.06-48.37 48.78 0 27.85 20.92 49.05 48.37 49.05h107.46c27.44 0 48.23-21.19 48.23-49.05.01-27.72-20.78-48.78-48.23-48.78zm0 82.33H124.03c-18.21 0-31.93-14.54-31.93-33.56 0-18.88 13.72-33.29 31.93-33.29h107.46c18.07 0 31.93 14.4 31.93 33.29 0 19.02-13.86 33.56-31.93 33.56z" />
      </g>
    </svg>
  );
};
