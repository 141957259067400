export enum Color {
  PRIMARY = "primary",
  LIGHT = "light",
  DARK = "dark",
  DARK_BLUE = "dark-blue",
}

export const colors: Color[] = Array.from(Object.values(Color));

export enum CSSColor {
  LIGHT_GREY = "#e6e6e6",
}
