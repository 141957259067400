import { removeBrowserStorageItem, StorageItem } from "helpers/browserStorage";
import { setIsLoggedInCookie } from "helpers/setLoggedInCookie";

export const logout = (redirectUrl: string | undefined) => {
  const keep = [StorageItem.CONSENTS_SOURCE_POINT];
  Object.values(StorageItem)
    .filter((item) => !keep.includes(item))
    .forEach((item) => {
      removeBrowserStorageItem(item);
    });
  setIsLoggedInCookie(false);
  if (redirectUrl) {
    // NOTICE: Hard reload to clear all internal app state.
    window.location.replace(redirectUrl);
  }
};
