import { useCallback } from "react";
import { usePlayer } from "components/Player/hooks/usePlayer";
import { logout } from "helpers/logout";

interface IUseLogoutCallback {
  (redirectUrl?: string): () => Promise<void>;
}

export const useLogoutCallback: IUseLogoutCallback = (redirectUrl) => {
  const { beforeLogout } = usePlayer();

  return useCallback(async () => {
    await beforeLogout();
    await logout(redirectUrl || location.href);
  }, [beforeLogout, redirectUrl]);
};
