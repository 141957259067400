import Cookies from "js-cookie";
import { I18nKey } from "locales/constants/I18nKey";
import React, { ReactElement, useState } from "react";
import { Button } from "components/Button/Button";
import { CloseButton } from "components/Modals/components/common/CloseButton/CloseButton";
import { prefixUrlWithLocale } from "helpers/url";
import { useI18n } from "hooks/useI18n";
import { useTypedRouter } from "hooks/useTypedRouter";
import { Locale } from "interfaces/interfaces";
import { Select } from "../components/Select/Select";
import { useGetLocaleFromCountryCode } from "./hooks/useGetLocaleFromCountryCode";
import styles from "./marketSelectorComponent.module.scss";

export const NextLocaleCookieName = "NEXT_LOCALE";

export const MarketSelectorComponent = (): ReactElement | null => {
  const router = useTypedRouter();
  const [isOpen, setIsOpen] = useState(true);
  const { i18n } = useI18n();

  const { locale, setLocale } = useGetLocaleFromCountryCode();

  const localeCookie = Cookies.get(NextLocaleCookieName);

  if (!locale || !isOpen || !!localeCookie || router.locale !== Locale.en)
    return null;
  const onClose = () => {
    Cookies.set(NextLocaleCookieName, Locale.en);
    setIsOpen(false);
  };

  const handleSelectChange = (event) => {
    setLocale(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    Cookies.set(NextLocaleCookieName, locale);
    router.push(router.pathname, prefixUrlWithLocale(router.asPath, locale), {
      locale,
    });
    setIsOpen(false);
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className={styles.marketSelector}>
        <div className={styles.container}>
          <div className={styles.selectWrapper}>
            <label htmlFor={"market"} className={styles.selectLabel}>
              {i18n(I18nKey.MARKET_SELECTOR_LABEL)}
            </label>
            <div className={styles.selectContainer}>
              <Select
                id={"market"}
                onChange={handleSelectChange}
                onBlur={handleSelectChange}
                locale={locale}
              />
            </div>
          </div>
          <div className={styles.buttonContainer}>
            <Button type="submit" primary className={styles.button}>
              {i18n(I18nKey.MARKET_SELECTOR_BUTTON)}
            </Button>
            <CloseButton className={styles.closeButton} onClick={onClose} />
          </div>
        </div>
      </div>
    </form>
  );
};
