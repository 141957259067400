import cx from "classnames";
import { useUser } from "contexts/UserContextProvider/UserContextProvider";
import Link from "next/link";
import React, {
  ReactElement,
  ReactNode,
  useCallback,
  useEffect,
  useRef,
} from "react";
import { getModalOriginFromUrl } from "components/Modals/context/ModalContextProvider";
import { useModal } from "components/Modals/hooks/useModal";
import { ModalType } from "components/Modals/modalTypes";
import { removeHash } from "helpers/removeHash";
import { trimTrailingSlash } from "helpers/trimTrailingSlash";
import {
  buildCollectionsUrl,
  buildCollectionUrl,
  buildContentUrl,
  buildHomepageUrl,
  buildPodcastUrl,
  buildStartpageUrl,
  prefixUrlWithLocale,
} from "helpers/url";
import { useTypedRouter } from "hooks/useTypedRouter";
import {
  AnyLink,
  IPrismicCollectionPageLink,
  IPrismicCollectionsPageLink,
  IPrismicContentPageLink,
  IPrismicExternalLink,
  IPrismicHomePageLink,
  IPrismicPodcastPageLink,
  IPrismicStartPageLink,
  LinkType,
  PageType,
} from "interfaces/interfaces";

export const isPrismicLink = <T extends AnyLink>(
  b: AnyLink | null,
  linkType: LinkType | LinkType[],
  pageType?: PageType | PageType[]
): b is T => {
  if (linkType === LinkType.Document) {
    return linkType === b?._linkType && pageType === b?._meta.type;
  }
  return linkType === b?._linkType;
};

interface IPrismicLink {
  children: ReactNode;
  link: AnyLink | null;
  className?: string;
  activeClassName?: string;
  scroll?: boolean;
  requiresLogin?: boolean;
}

export const PrismicLink = ({
  children,
  link,
  className,
  activeClassName,
  requiresLogin,
  scroll = true,
}: IPrismicLink): ReactElement | null => {
  // Get the current page path.
  const { locale, asPath } = useTypedRouter();
  const path = removeHash(asPath);
  const current = trimTrailingSlash(prefixUrlWithLocale(path, locale));
  const { openModal } = useModal();
  const router = useTypedRouter();
  const { user } = useUser();

  let href;
  let classNames;

  if (isPrismicLink<IPrismicExternalLink>(link, LinkType.Web)) {
    href = link.url;
  }

  if (
    isPrismicLink<IPrismicHomePageLink>(
      link,
      LinkType.Document,
      PageType.HOME_PAGE
    )
  ) {
    href = buildHomepageUrl(link._meta.lang);

    // NOTICE: This is a hack to make the "home" link active when the subpage
    // "history" is the current page. There is no simple way to do this.
    // This solution good enough considering that Podplay will not be live that
    // much longer.
    const isSpecialActive = current.endsWith("/home/history");

    classNames = activeClassName
      ? cx(className, {
          [activeClassName]: current === href || isSpecialActive,
        })
      : className;
  }

  if (
    isPrismicLink<IPrismicStartPageLink>(
      link,
      LinkType.Document,
      PageType.START_PAGE
    )
  ) {
    href = buildStartpageUrl(link._meta.lang);
    classNames = activeClassName
      ? cx(className, {
          [activeClassName]: current === href,
        })
      : className;
  }

  if (
    isPrismicLink<IPrismicPodcastPageLink>(
      link,
      LinkType.Document,
      PageType.PODCAST_PAGE
    )
  ) {
    href = buildPodcastUrl(link._meta.uid, undefined, link._meta.lang);
    classNames = activeClassName
      ? cx(className, { [activeClassName]: current === href })
      : className;
  }

  if (
    isPrismicLink<IPrismicContentPageLink>(
      link,
      LinkType.Document,
      PageType.CONTENT_PAGE
    )
  ) {
    href = buildContentUrl(link.slug, link._meta.lang);
    classNames = activeClassName
      ? cx(className, { [activeClassName]: current === href })
      : className;
  }

  if (
    isPrismicLink<IPrismicCollectionPageLink>(
      link,
      LinkType.Document,
      PageType.COLLECTION_PAGE
    )
  ) {
    href = buildCollectionUrl(link._meta.uid, link._meta.lang);
    classNames = activeClassName
      ? cx(className, { [activeClassName]: current === href })
      : className;
  }

  if (
    isPrismicLink<IPrismicCollectionsPageLink>(
      link,
      LinkType.Document,
      PageType.COLLECTIONS_PAGE
    )
  ) {
    href = buildCollectionsUrl(link._meta.lang);
    classNames = activeClassName
      ? cx(className, { [activeClassName]: current === href })
      : className;
  }

  const gotoHref = useCallback(async () => {
    await router.push(href);
  }, [href, router]);

  const gotoHrefRef = useRef(gotoHref);
  useEffect(() => {
    gotoHrefRef.current = gotoHref;
  }, [gotoHref]);

  const handleOnClick = (event) => {
    if (requiresLogin && !user) {
      event.preventDefault();
      openModal(ModalType.LOGIN_OR_SIGNUP, {
        origin: getModalOriginFromUrl(href, locale),
        callback: gotoHrefRef,
      });
    }
  };

  if (href && children) {
    return (
      <Link
        href={href}
        scroll={scroll}
        onClick={requiresLogin ? handleOnClick : undefined}
        className={classNames}
      >
        {children}
      </Link>
    );
  }

  return null;
};
