import React, { ReactElement } from "react";
import styles from "./previewHeader.module.scss";

export const PreviewHeader = (): ReactElement => {
  return (
    <div className={styles.header}>
      <span>This page is in preview mode.</span>
      {/* eslint-disable-next-line @next/next/no-html-link-for-pages */}
      <a href="/api/exit-preview">Exit preview</a>
    </div>
  );
};
