import classNames from "classnames";
import { I18nKey } from "locales/constants/I18nKey";
import React, { ReactElement } from "react";
import { useI18n } from "hooks/useI18n";
import { Locale } from "interfaces/interfaces";
import styles from "./select.module.scss";

interface IProps {
  onChange: ((event: React.ChangeEvent<HTMLSelectElement>) => void) | undefined;
  onBlur?: (event: React.FocusEvent<HTMLSelectElement>) => void;
  locale: Locale;
  id: string;
  className?: string;
  defaultLabel?: string;
}

export const Select = ({
  onChange,
  onBlur,
  locale,
  id,
  className,
  defaultLabel,
}: IProps): ReactElement => {
  const { i18n } = useI18n();
  return (
    <select
      id={id}
      aria-label={`select-aria-label-${id}`}
      data-testid={`select-${id}`}
      value={locale}
      onChange={onChange}
      onBlur={onBlur}
      className={classNames(styles.select, className)}
    >
      <option value="" disabled>
        {defaultLabel
          ? defaultLabel
          : i18n(I18nKey.MARKET_SELECTOR_SELECT_DEFAULT)}
      </option>
      <option value={Locale.en}>{i18n(I18nKey.MARKET_SELECTOR_GLOBAL)}</option>
      <option value={Locale["no-no"]}>
        {i18n(I18nKey.MARKET_SELECTOR_NORWAY)}
      </option>
      <option value={Locale["fi-fi"]}>
        {i18n(I18nKey.MARKET_SELECTOR_FINLAND)}
      </option>
      <option value={Locale["sv-se"]}>
        {i18n(I18nKey.MARKET_SELECTOR_SWEDEN)}
      </option>
      {/*<option value={Locale["en-gb"]}>*/}
      {/*  {i18n(I18nKey.MARKET_SELECTOR_UK)}*/}
      {/*</option>*/}
    </select>
  );
};
