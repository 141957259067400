import classNames from "classnames";
import React, {
  ButtonHTMLAttributes,
  PropsWithChildren,
  ReactElement,
} from "react";
import { Color } from "helpers/styles/Color";
import styles from "./button.module.scss";

interface IProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  backgroundColorContext?: Color;
  color?: Color;
  isLoading?: boolean;
  primary?: boolean;
  className?: string;
  type: "button" | "submit" | "reset";
}

export const Button = React.forwardRef<
  HTMLButtonElement,
  PropsWithChildren<IProps>
>((props: PropsWithChildren<IProps>, ref): ReactElement => {
  const {
    primary,
    color,
    backgroundColorContext,
    className,
    isLoading,
    ...rest
  } = props;
  return (
    <button
      ref={ref}
      data-ignore-a11y={primary ? "color-contrast" : ""}
      className={classNames(className, {
        [styles.button]: true,
        [styles.primary]: primary,
        [styles[`color-${color ? color : Color.DARK}`]]: !primary,
        [styles.backgroundContextDark]: backgroundColorContext === Color.DARK,
        [styles.backgroundContextDarkBlue]:
          backgroundColorContext === Color.DARK_BLUE,
        [styles.backgroundContextLight]:
          backgroundColorContext === Color.LIGHT || !backgroundColorContext,
        [styles.isLoading]: isLoading,
      })}
      aria-label={props["aria-label"]}
      {...rest}
    />
  );
});

Button.displayName = "Button";
