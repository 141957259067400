import { useUser } from "contexts/UserContextProvider/UserContextProvider";
import { I18nKey } from "locales/constants/I18nKey";
import Link from "next/link";
import { useRouter } from "next/router";
import React, { ReactElement, useEffect, useState } from "react";
import { PreviewHeader } from "components/ExitPreview/PreviewHeader";
import { PersonIcon } from "components/Icons/PersonIcon";
import { useModal } from "components/Modals/hooks/useModal";
import { ModalType } from "components/Modals/modalTypes";
import { PodplayLogo } from "components/PodplayLogo/PodplayLogo";
import { PrismicLink } from "components/PrismicLink/PrismicLink";
import { Search } from "components/Search/Search";
import { getNameInitials } from "helpers/getNameInitials";
import { buildHomepageUrl, buildStartpageUrl, buildUserUrl } from "helpers/url";
import { useI18n } from "hooks/useI18n";
import { useTypedRouter } from "hooks/useTypedRouter";
import { IHeader } from "interfaces/interfaces";
import styles from "./header.module.scss";

interface IProps {
  header: IHeader;
}

interface IHeaderProps {
  header: IHeader;
}

const SiteHeader = ({ header }: IHeaderProps): ReactElement => {
  const router = useTypedRouter();
  const { openModal } = useModal();
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const { user } = useUser();
  const { i18n } = useI18n();

  const handleOnProfileClick = () => {
    const url = buildUserUrl(router.locale);
    void router.push(url);
  };

  const handleOnLoginClick = () => {
    setIsSearchOpen(false);
    openModal(ModalType.LOGIN_OR_SIGNUP);
  };

  useEffect(() => {
    const handleRouteChange = () => {
      setIsSearchOpen(false);
    };

    router.events.on("routeChangeStart", handleRouteChange);
    return () => {
      router.events.off("routeChangeStart", handleRouteChange);
    };
  }, [router]);

  const scrollIntoView = (event) => {
    const { currentTarget } = event;
    currentTarget.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "center",
    });
  };

  return (
    <>
      <header className={styles.header}>
        <Link
          className={styles.logo}
          aria-label={header.startpage}
          href={user ? buildHomepageUrl() : buildStartpageUrl()}
        >
          <PodplayLogo height={14} fill="white" />
        </Link>
        <ul className={styles.iconMenu}>
          {user ? (
            <li>
              <button
                type="button"
                data-testid={"header-profilePage-link"}
                aria-label={header.profile_page}
                className={styles.profileButton}
                onClick={handleOnProfileClick}
              >
                <span>
                  {user?.profile_name ? (
                    getNameInitials(user.profile_name)
                  ) : (
                    <PersonIcon size={18} fill="white" />
                  )}
                </span>
              </button>
            </li>
          ) : (
            <li>
              <button
                type="button"
                data-testid={"header-signupOrLogin-button"}
                aria-label={header.profile_page}
                className={styles.loginButton}
                onClick={handleOnLoginClick}
              >
                <span>{i18n(I18nKey.SITE_HEADER_MENU_ITEM_LOGIN)}</span>
              </button>
            </li>
          )}
          <Search
            label={header.search}
            isOpen={isSearchOpen}
            setIsOpen={setIsSearchOpen}
          />
        </ul>
        <div className={styles.mainMenuWrapper}>
          <ul className={styles.mainMenu}>
            {header.menu.map((item, index) => (
              <li key={index} onClick={scrollIntoView}>
                <PrismicLink
                  link={item.link}
                  activeClassName={styles.active}
                  scroll={false}
                  requiresLogin={item.requires_login}
                >
                  {item.label}
                </PrismicLink>
              </li>
            ))}
          </ul>
        </div>
      </header>
    </>
  );
};

export const Header = ({ header }: IProps): ReactElement => {
  const { isPreview } = useRouter();
  return (
    <>
      {isPreview && <PreviewHeader />}
      {header && <SiteHeader header={header} />}
    </>
  );
};
