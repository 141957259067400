import { useUser } from "contexts/UserContextProvider/UserContextProvider";
import Cookies from "js-cookie";
import { I18nKey } from "locales/constants/I18nKey";
import Link from "next/link";
import React, { ReactElement } from "react";
import { useModal } from "components/Modals/hooks/useModal";
import { ModalType } from "components/Modals/modalTypes";
import { PrismicLink } from "components/PrismicLink/PrismicLink";
import { NextLocaleCookieName } from "components/marketSelectors/MarketSelector/MarketSelectorComponent";
import { loadPrivacyManagerModal } from "helpers/loadSourcePointPrivacyManagerModal";
import { removeHash } from "helpers/removeHash";
import { trimTrailingSlash } from "helpers/trimTrailingSlash";
import { buildStartpageUrl, prefixUrlWithLocale } from "helpers/url";
import { useI18n } from "hooks/useI18n";
import { useLogoutCallback } from "hooks/useLogoutCallback";
import { useTypedRouter } from "hooks/useTypedRouter";
import { IFooter, Locale } from "interfaces/interfaces";
import styles from "./footer.module.scss";

interface IFooterProps {
  block: IFooter;
}

interface IMarketLinkProps {
  locale: Locale;
  children: ReactElement | string;
}

// eslint-disable-next-line react/display-name
const MarketLink = (props: IMarketLinkProps) => {
  const { push, pathname, asPath } = useTypedRouter();

  // Remove hash from path.
  const path = removeHash(asPath);
  const href = trimTrailingSlash(prefixUrlWithLocale(path, props.locale));
  const onClick = (event) => {
    event.preventDefault();
    Cookies.set(NextLocaleCookieName, props.locale);
    push(pathname, href, {
      locale: props.locale,
    });
  };

  return (
    <a href={href} onClick={onClick}>
      {props.children}
    </a>
  );
};

export const Footer = (props: IFooterProps): ReactElement => {
  const { i18n } = useI18n();
  const { user } = useUser();
  const { pathname, locale } = useTypedRouter();
  const { openModal } = useModal();

  // Redirect to start-page if we are on a user-page when logging out.
  const logoutRedirectUrl = pathname.includes("/user")
    ? buildStartpageUrl(locale)
    : undefined;
  const onLogoutClick = useLogoutCallback(logoutRedirectUrl);

  const onClickSignup = (event) => {
    event.preventDefault();
    openModal(ModalType.SIGNUP);
  };

  const onClickLogin = (event) => {
    event.preventDefault();
    openModal(ModalType.LOGIN);
  };

  return (
    <footer className={styles.footer}>
      <div className={styles.top}>
        <div className={styles.lists}>
          {props.block.footerBody.map((menu) => (
            <section key={menu.primary.heading} className={styles.list}>
              <h2>{menu?.primary?.heading}</h2>
              <ul>
                {menu?.fields.map((item, index) => {
                  return (
                    <li key={index}>
                      <PrismicLink link={item.link}>{item.label}</PrismicLink>
                    </li>
                  );
                })}
              </ul>
            </section>
          ))}

          <section className={styles.list}>
            <h2>{props.block.market_menu_heading}</h2>
            <ul>
              <li>
                <MarketLink locale={Locale["no-no"]}>
                  {i18n(I18nKey.MARKET_SELECTOR_NORWAY)}
                </MarketLink>
              </li>
              <li>
                <MarketLink locale={Locale["fi-fi"]}>
                  {i18n(I18nKey.MARKET_SELECTOR_FINLAND)}
                </MarketLink>
              </li>
              <li>
                <MarketLink locale={Locale["sv-se"]}>
                  {i18n(I18nKey.MARKET_SELECTOR_SWEDEN)}
                </MarketLink>
              </li>
              <li>
                <MarketLink locale={Locale["en"]}>
                  {i18n(I18nKey.MARKET_SELECTOR_GLOBAL)}
                </MarketLink>
              </li>
            </ul>
          </section>
          <section className={styles.list}>
            <h2>{props.block.user_menu_heading}</h2>
            <ul>
              <li>
                <button
                  type="button"
                  className={styles.buttonLink}
                  onClick={loadPrivacyManagerModal}
                >
                  {i18n(I18nKey.SITE_FOOTER_ITEM_PRIVACY_PREFERENCES)}
                </button>
              </li>
              {user ? (
                <li>
                  <button
                    data-testid="footer-logout-button"
                    type="button"
                    className={styles.buttonLink}
                    onClick={onLogoutClick}
                  >
                    {i18n(I18nKey.SITE_HEADER_MENU_ITEM_SIGN_OUT)}
                  </button>
                </li>
              ) : (
                <>
                  <li>
                    <button
                      type="button"
                      data-testid="footer-signup-button"
                      onClick={onClickSignup}
                    >
                      {i18n(I18nKey.SITE_HEADER_MENU_ITEM_CREATE_ACCOUNT)}
                    </button>
                  </li>
                  <li>
                    <button
                      type="button"
                      data-testid="footer_login_button"
                      onClick={onClickLogin}
                    >
                      {i18n(I18nKey.SITE_HEADER_MENU_ITEM_LOGIN)}
                    </button>
                  </li>
                </>
              )}
            </ul>
          </section>
        </div>
        <section className={styles.download}>
          {props.block.apple_url && (
            <Link legacyBehavior href={props.block.apple_url}>
              <a
                className={styles.store}
                aria-label={props.block.apple_label}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  alt={props.block.apple_label}
                  src={"/assets/logos/apple-appstore.svg"}
                  width={135}
                  height={39}
                />
              </a>
            </Link>
          )}

          {props.block.google_url && (
            <Link legacyBehavior href={props.block.google_url}>
              <a
                className={styles.store}
                aria-label={props.block.google_label}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  alt={props.block.google_label}
                  src={"/assets/logos/google-playstore.svg"}
                  width={135}
                  height={39}
                />
              </a>
            </Link>
          )}
        </section>
      </div>

      <section className={styles.end}>
        {props.block.instagram_url && (
          <Link legacyBehavior href={props.block.instagram_url}>
            <a
              className={styles.leftLogo}
              aria-label="Instagram"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                alt={"Instagram"}
                width={20}
                height={20}
                src={"/assets/logos/instagram.svg"}
              />
            </a>
          </Link>
        )}
        {props.block.facebook_url && (
          <Link legacyBehavior href={props.block.facebook_url}>
            <a
              className={styles.leftLogo}
              aria-label="Facebook"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                alt={"Facebook"}
                width={20}
                height={20}
                src={"/assets/logos/facebook.svg"}
              />
            </a>
          </Link>
        )}
        {props.block.bauermedia_url && (
          <Link legacyBehavior href={props.block.bauermedia_url}>
            <a
              className={styles.rightLogo}
              aria-label="Bauer Media"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                alt={"Bauer Media"}
                width={155}
                height={11}
                src={"/assets/logos/bauermedia.svg"}
              />
            </a>
          </Link>
        )}
      </section>
    </footer>
  );
};
